// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import debounce from 'lodash.debounce';

class SiteHeader {
    constructor ($element) {
        this.$siteHeader = $element;
        this.$toggles = this.$siteHeader.querySelectorAll('[data-tooltip-trigger]');
        this.$toggleSwitches = this.$siteHeader.querySelectorAll('.theme-switch__switch');
        this.$toolTipPowersave = this.$siteHeader.querySelector('.theme-switch__tool-tip--powersave');
        this.$toolTipPowerlose = this.$siteHeader.querySelector('.theme-switch__tool-tip--powerlose');
        this.$AllToggleClose = this.$siteHeader.querySelectorAll('.theme-switch__close');
        this.$disagree = this.$siteHeader.querySelector('.theme-switch__cta--disagree');
        this.$agree = this.$siteHeader.querySelector('.theme-switch__cta--agree');
        this.$burger = this.$siteHeader.querySelector('[data-site-header="burger"]');
        this.$close = this.$siteHeader.querySelector('[data-site-header="close"]');
        this.$navList = this.$siteHeader.querySelector('[data-nav="items"]');
        this.$mainNav = this.$siteHeader.querySelector('[data-site-header="main-nav"]');
        this.$mainNavLevelOne = this.$mainNav.querySelector('.main-nav--level-1');
        this.$desktopNav = this.$siteHeader.querySelector('[data-nav-desktop]');
        this.$magicLine = this.$siteHeader.querySelector('[data-nav="magic-line"]');
        this.$shareChartLight = document.querySelector('[data-share-chart="light"]');
        this.$shareChartDark = document.querySelector('[data-share-chart="dark"]');
        this.themeDarkClass = 'theme--dark';
        this.themeLightClass = 'theme--light';
        window.darkModeState = false;
        this.openState = false;
        this.clicked = false;
        this.lastScrollPos = 0;
        this.isDesktopBp = window.matchMedia('(min-width: 1280px)');

        this.initialize();
    }

    initialize () {
        window.eventScroller.customFunctions.push(() => {
            this.checkScrollDirection();
        });

        this.setEvents();

        window.darkModeState = localStorage.getItem('dark-mode') === 'true';

        if (localStorage.getItem('dark-mode')) {
            this.themeIt(localStorage.getItem('dark-mode') === 'true');
        }

        this.allowJustOneSub();
        this.handleSubMenu();
        this.resizeMainNav();

        this.showEqsChart();
        setTimeout(() => {
            this.magicLine();
        }, 100);
    }

    setEvents () {
        let timeoutId;

        this.$toggleSwitches.forEach($toggleSwitch => {
            $toggleSwitch.addEventListener('click', () => {
                this.showEqsChart();

                if (this.isDesktopBp.matches) {
                    // if is light mode
                    this.$toggles.forEach(($toggle, index) => {
                        if (index === 0) {
                            if (!$toggle.classList.contains('theme-switch__switch--active')) {
                                window.darkModeState = true;
                                this.themeIt(window.darkModeState);
                                this.showEqsChart();
                            } else {
                                // in dark mode show tool tip
                                this.showToolTip(this.$toolTipPowerlose);
                            }
                        }
                    });
                } else {
                    window.darkModeState = !window.darkModeState;
                    this.themeIt(window.darkModeState);
                }
            });

            $toggleSwitch.addEventListener('click', () => {
                this.hideToolTip(this.$toolTipPowersave);
                this.showEqsChart();
            });
        });

        // show tool tip on hover in light mode
        this.$toggles.forEach($toggle => {
            $toggle.addEventListener('mouseover', (e) => {
                this.showEqsChart();
                if (this.isDesktopBp.matches) {
                    if (!$toggle.classList.contains('theme-switch__switch--active')) {
                        timeoutId = setTimeout(() => {
                            this.showToolTip(this.$toolTipPowersave);
                        }, 500);
                    }
                }

                this.showEqsChart();
            });

            $toggle.addEventListener('mouseout', () => {
                this.showEqsChart();
                clearTimeout(timeoutId);
            });
        });

        this.$disagree.addEventListener('click', () => {
            if (this.isDesktopBp.matches) {
                this.hideToolTip(this.$toolTipPowerlose);
            }
        });

        this.$AllToggleClose.forEach(close => {
            if (close.parentNode === this.$toolTipPowerlose) {
                // is in DARK mode
                close.addEventListener('click', () => {
                    this.hideToolTip(this.$toolTipPowerlose);
                });
            } else {
                close.addEventListener('click', () => {
                    this.hideToolTip(this.$toolTipPowersave);
                });
            }
        });

        this.$agree.addEventListener('click', () => {
            if (this.isDesktopBp.matches) {
                this.hideToolTip(this.$toolTipPowerlose);
                window.darkModeState = !window.darkModeState;
                this.themeIt(window.darkModeState);
            }
        });

        this.$burger.addEventListener('click', () => {
            this.handleMainMenu();
        });

        this.$close.addEventListener('click', () => {
            this.handleMainMenu('close');
        });

        // close navigation on outside click
        document.addEventListener('click', (e) => {
            if (e.target.classList.contains('site-header__close-navi-trigger')) {
                this.handleMainMenu('close');
            }
        });

        // close navigation on ESC key
        document.addEventListener('keydown', (e) => {
            let isEscape = false;
            if ('key' in e) {
                isEscape = (e.key === 'Escape' || e.key === 'Esc');
            } else {
                isEscape = (e.keyCode === 27);
            }
            if (isEscape === true && this.$siteHeader.classList.contains('is--open')) {
                this.handleMainMenu('close');
            }
        });
    }

    checkScrollDirection () {
        window.eventScroller.customFunctions.push(() => {
            if (!this.$siteHeader.classList.contains('is--open')) {
                if (window.scrollDirection === 'down') {
                    if (window.scrollY > 0) {
                        this.$siteHeader.classList.add('is--sticky');
                        if (this.$siteHeader.classList.contains('is--visible')) {
                            this.$siteHeader.classList.remove('is--visible');
                        }
                    }
                }

                if (window.scrollDirection === 'up') {
                    this.$siteHeader.classList.add('is--visible');
                }

                if (window.scrollY === 0) {
                    this.$siteHeader.classList.remove('is--sticky');
                    this.$siteHeader.classList.remove('is--visible');
                }
            }
        });
    }

    themeIt (state) {
        const $buttons = document.querySelectorAll('.button');
        $buttons.forEach(btn => {
            btn.classList.toggle(this.themeDarkClass, state);
        });
        document.body.classList.toggle(this.themeDarkClass, state);
        document.body.classList.toggle(this.themeLightClass, !state);
        if (state === false) {
            localStorage.removeItem('dark-mode');
        } else {
            localStorage.setItem('dark-mode', true);
        }

        this.$toggleSwitches.forEach($toggleSwitch => {
            $toggleSwitch.classList.toggle('theme-switch__switch--active', state);
        });

        this.$toggles.forEach($toggle => {
            $toggle.classList.toggle('theme-switch__switch--active', state);
        });

        const darkmode = new CustomEvent('darkmode', { detail: { darkmode: state } });

        // Löse das Ereignis aus
        document.dispatchEvent(darkmode);
    }

    showEqsChart () {
        if (this.$shareChartDark && this.$shareChartLight) {
            if (window.darkModeState === false) {
                this.$shareChartDark.classList.remove('aktien__frame--is-active');
                this.$shareChartLight.classList.add('aktien__frame--is-active');
            } else {
                this.$shareChartLight.classList.remove('aktien__frame--is-active');
                this.$shareChartDark.classList.add('aktien__frame--is-active');
            }
        }
    }

    showToolTip (el) {
        el.classList.add('theme-switch__tool-tip--show');
    }

    hideToolTip (el) {
        el.classList.remove('theme-switch__tool-tip--show');
    }

    closeAllSubs (target) {
        const $allActiveSubNav = this.$mainNav.querySelectorAll('[data-nav="toggle"]');
        const $allActiveSubNavHeads = this.$mainNav.querySelectorAll('[data-subnav="head"]');
        $allActiveSubNavHeads.forEach($allActiveSubNavHead => {
            if ($allActiveSubNavHead.classList.contains('is--active')) {
                $allActiveSubNavHead.classList.remove('is--active');
            }
        });
        $allActiveSubNav.forEach($activeSubNav => {
            if (target !== $activeSubNav) {
                $activeSubNav.checked = false;
            }
        });
        this.$mainNav.querySelector('.main-nav--level-1').classList.remove('is--open');
    }

    allowJustOneSub () {
        const $subNavs = this.$mainNav.querySelectorAll('[data-subnav-level="2"]');
        $subNavs.forEach($activeSubNav => {
            $activeSubNav.addEventListener('click', () => {
                this.$mainNavLevelOne.classList.toggle('is--open', $activeSubNav.checked);
                $subNavs.forEach(checkbox => {
                    if (checkbox !== $activeSubNav) {
                        checkbox.checked = false;
                    }
                });
            });
        });
    }

    handleSubMenu () {
        const $subNavs = this.$mainNav.querySelectorAll('[data-subnav-level="1"]');
        $subNavs.forEach($subNav => {
            $subNav.addEventListener('change', (e) => {
                this.closeAllSubs(e.target);
            });
        });
    }

    resizeMainNav () {
        const $allSubNavs = this.$mainNav.querySelectorAll('[data-nav="toggle"]');

        const calcHeight = (element) => {
            const subNavContent = element.parentElement.querySelector('.sub-nav__content');
            return subNavContent.offsetHeight + 64;
        };

        const handleResize = () => {
            if (window.innerWidth < 1280) {
                let subNavHeight = 'auto';

                $allSubNavs.forEach($otherSubNav => {
                    if ($otherSubNav.checked) {
                        subNavHeight = `${calcHeight($otherSubNav)}px`;
                    }
                });

                this.$mainNavLevelOne.style.height = subNavHeight;
            } else {
                this.$mainNavLevelOne.style.height = 'auto';
            }
        };

        // Initial call to handleResize
        handleResize();

        // Recall handleResize
        window.addEventListener('resize', debounce(() => {
            handleResize();
        }, 100));

        $allSubNavs.forEach($subNav => {
            $subNav.addEventListener('change', () => {
                handleResize();
            });
        });
    }

    handleMainMenu (activeName) {
        this.openState = !this.openState;
        this.$siteHeader.classList.toggle('is--open', this.openState);

        if (this.openState === true) {
            // disableBodyScroll(this.$mainNav);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
            // enableBodyScroll(this.$mainNav);
        }

        if (activeName === 'close') {
            this.closeAllSubs();
        }
    }

    magicLine () {
        const $activeItem = this.$desktopNav.querySelector('.desktop-nav__list-item.is--active');
        const allNavItems = this.$desktopNav.querySelectorAll('[data-nav="item"]');
        let activeItemXPos;
        let activeItemWidth;
        this.clicked = false;

        if ($activeItem) {
            activeItemXPos = Math.round($activeItem.offsetLeft);
            activeItemWidth = Math.round($activeItem.getBoundingClientRect().width);
            this.$magicLine.style.opacity = 1;
            this.$magicLine.style.setProperty('--posX', `${activeItemXPos}px`);
            this.$magicLine.style.setProperty('--width', `${activeItemWidth}px`);
        }

        allNavItems.forEach(link => {
            const linkX = Math.round(link.offsetLeft);
            const linkW = Math.round(link.getBoundingClientRect().width);

            link.addEventListener('mouseover', () => {
                this.$magicLine.style.opacity = 1;
                this.$magicLine.style.setProperty('--posX', `${linkX}px`);
                this.$magicLine.style.setProperty('--width', `${linkW}px`);
            });

            link.addEventListener('click', (e) => {
                const activeName = link.querySelector('[data-item-name]').dataset.itemName;

                if (link.querySelector('a')) {
                    e.preventDefault();
                    const href = link.querySelector('a').href;
                    setTimeout(() => {
                        window.location.href = href;
                    }, 310);
                } else {
                    this.handleMainMenu(activeName);
                }
                this.clicked = true;
                this.$magicLine.style.setProperty('--posX', `${linkX}px`);
                this.$magicLine.style.setProperty('--width', `${linkW}px`);
            });
        });

        this.$navList.addEventListener('mouseleave', (e) => {
            if ($activeItem && this.clicked === false) {
                this.$magicLine.style.setProperty('--posX', `${activeItemXPos}px`);
                this.$magicLine.style.setProperty('--width', `${activeItemWidth}px`);
            } else if (this.clicked === true) {
                this.$magicLine.style.opacity = 0;
                this.clicked = false;
            } else {
                this.$magicLine.style.opacity = 0;
            }
        });
    }
}

export default SiteHeader;

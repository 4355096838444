import './app.scss';
import domready from 'domready';
import 'lazysizes';
// import { getOffset, smoothScrollTo } from './utilities/js/helper';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import SiteHeader from './layout/site-header/site-header';

import Splitting from 'splitting';

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    speed: 500,
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    }
};

window.eventScroller = new EventScroller();
window.resizeHandler = new ResizeHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;

const checkInview = () => {
    const options = {
        rootMargin: '0px 0px -10% 0px',
        threshold: 0
    };

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('inview');
                observer.unobserve(entry.target);
            }
        });
    }, options);

    const $inviews = document.querySelectorAll('[data-inview]:not(.inview)');
    $inviews.forEach((target) => {
        observer.observe(target);
    });
};

const splitText = () => {
    const allSplitText = document.querySelectorAll('[data-splitting]');

    allSplitText.forEach(splitText => {
        const results = Splitting({ target: splitText, by: 'lines' });// eslint-disable-line
    });
};

const initContent = ($content) => {
    console.log('start app');

    loadLazyImages();
};

const handleUsercentrics = () => {
    const maxTries = 100;
    let currentTry = 0;

    const addStyleTag = ($host) => {
        const $shadowRoot = $host.shadowRoot;
        $host.style.position = 'relative';
        $host.style.zIndex = '1';
        const $styleTag = document.createElement('style');
        let styleInformation = '';
        styleInformation = styleInformation + '\n.liwSgQ { min-height: auto; border-radius: 8px 8px 0 0; box-shadow: rgb(0 0 0 / 22%) 0px 5px 26px 0px, rgb(0 0 0 / 30%) 0px 20px 28px 0px; }';
        styleInformation = styleInformation + '\n#uc-show-more font { font-family: "Noto Sans", Arial, sans-serif !important; }';
        styleInformation = styleInformation + '\n[data-testid="uc-more-button"],[data-testid="uc-deny-all-button"],[data-testid="uc-accept-all-button"] { font-family: "Noto Sans", Arial, sans-serif !important; height: 56px; }';
        $styleTag.innerHTML = styleInformation;

        $shadowRoot.appendChild($styleTag);
    };

    const userCentricsInterval = window.setInterval(() => {
        const $usercentrics = document.getElementById('usercentrics-root');
        if ($usercentrics || currentTry === maxTries) {
            clearInterval(userCentricsInterval);
            if ($usercentrics) {
                addStyleTag($usercentrics);
            }
        }
        currentTry = currentTry + 1;
    }, 100);
};

domready(function () {
    const initApplication = () => {
        initContent(document);

        handleUsercentrics();

        window.eventScroller.init();
        window.resizeHandler.init();

        const $siteheader = document.querySelector('[data-site-header]');
        if ($siteheader) {
            const siteheaderAPI = new SiteHeader($siteheader);
            $siteheader.API = siteheaderAPI;
        }

        splitText();

        window.setTimeout(() => {
            checkInview();
        }, 750);

        window.resizeHandler.customFunctions.push(() => {
            splitText();
            console.log('resized splitText');
        });

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });
        window.dispatchEvent(contentUpdateEvent);

        document.body.classList.add('page--loaded');
        window.addEventListener('beforeunload', () => {
            document.body.classList.remove('page--loaded');
        });

        window.addEventListener('pageshow', (event) => {
            const historyTraversal = event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
            if (historyTraversal) {
                window.location.reload();
            }
        });
    };

    initApplication();
});

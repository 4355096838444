/**
 * usage
 * import { EventScroller } from './utilities/js/resize-handler';
 *
 * const eventScroller = new EventScroller();
 * eventScroller.init();
 *
 * eventScroller.customFunctions.push(() => { // do stuff });
 */

class EventScroller {
    constructor () {
        this.lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.customFunctions = [];
        window.scrollDirection = 'down';
        window.scrollPosition = this.lastScrollTop;
    }

    init () {
        this.bindEvents();
    }

    bindEvents () {
        let ticking = false;

        window.addEventListener('scroll', () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    for (let i = 0; i < this.customFunctions.length; i++) {
                        const customFunction = this.customFunctions[i];

                        if (typeof customFunction === 'function') {
                            customFunction();
                        }
                    }

                    const position = window.pageYOffset || document.documentElement.scrollTop;
                    window.scrollPosition = position;

                    if (position > this.lastScrollTop) {
                        window.scrollDirection = 'down';
                    } else {
                        window.scrollDirection = 'up';
                    }

                    this.lastScrollTop = position <= 0 ? 0 : position; // For Mobile or negative scrolling

                    ticking = false;
                });

                ticking = true;
            }
        }, { passive: true });
    }

    // helper function to check, if element is in view
    onScreen ($element, tolerance = 0, ignoreBefore = false) {
        const bounds = $element.getBoundingClientRect();

        if (ignoreBefore) {
            return (((bounds.top + tolerance) - window.innerHeight) <= 0 && bounds.bottom > 0);
        } else {
            if (bounds.top <= 0) {
                return true;
            } else {
                return (((bounds.top + tolerance) - window.innerHeight) <= 0 && bounds.bottom > 0);
            }
        }
    }
}

export { EventScroller };
